<!DOCTYPE html>
<html lang="id">
    <head>
        <meta charset="UTF-8" />
        <title>Prospector</title>
        <meta name="robots" content="max-image-preview:large" />
        <link
            rel="stylesheet"
            id="google-fonts-1-css"
            href="https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CSource+Sans+Pro%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=swap&#038;ver=6.3"
            media="all"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <!-- Facebook Pixel Code -->
        <script>
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '4044717268938032');
            fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=4044717268938032&ev=PageView&noscript=1" /></noscript>
        <!-- End Facebook Pixel Code -->
    </head>
    <body class="home page-template page-template-elementor_canvas page page-id-93 wp-embed-responsive elementor-default elementor-template-canvas elementor-kit-5 elementor-page elementor-page-93">
        <div data-elementor-type="wp-page" data-elementor-id="93" class="elementor elementor-93">
            <section data-section-id="section-1" class="elementor-section elementor-top-section elementor-element elementor-element-5ccce7bf elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-423d8095" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-531f4f65 elementor-widget elementor-widget-image" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-widget-image {
                                            text-align: center;
                                        }
                                        .elementor-widget-image a {
                                            display: inline-block;
                                        }
                                        .elementor-widget-image a img[src$='.svg'] {
                                            width: 48px;
                                        }
                                        .elementor-widget-image img {
                                            vertical-align: middle;
                                            display: inline-block;
                                        }
                                    </style>
                                    <img decoding="async" width="879" height="253" [src]="settings.get('branding.logo_light')" class="attachment-large size-large wp-image-66" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-661dfe69 animated-fast elementor-widget elementor-widget-heading" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-heading-title {
                                            padding: 0;
                                            margin: 0;
                                            line-height: 1;
                                        }
                                        .elementor-widget-heading .elementor-heading-title[class*='elementor-size-'] > a {
                                            color: inherit;
                                            font-size: inherit;
                                            line-height: inherit;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-small {
                                            font-size: 15px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-medium {
                                            font-size: 19px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-large {
                                            font-size: 29px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-xl {
                                            font-size: 39px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
                                            font-size: 59px;
                                        }
                                    </style>
                                    <h2 class="elementor-heading-title elementor-size-default header-title" trans>{{ content.headerTitle }}</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-691ae80c animated-fast elementor-widget elementor-widget-text-editor" data-element_type="widget" data-settings='{"_animation":"fadeInUp","_animation_delay":5}' data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
                                            background-color: #818a91;
                                            color: #fff;
                                        }
                                        .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
                                            color: #818a91;
                                            border: 3px solid;
                                            background-color: transparent;
                                        }
                                        .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
                                            margin-top: 8px;
                                        }
                                        .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
                                            width: 1em;
                                            height: 1em;
                                        }
                                        .elementor-widget-text-editor .elementor-drop-cap {
                                            float: left;
                                            text-align: center;
                                            line-height: 1;
                                            font-size: 50px;
                                        }
                                        .elementor-widget-text-editor .elementor-drop-cap-letter {
                                            display: inline-block;
                                        }
                                    </style>
                                    <p class="header-description" [innerHTML]="content.headerSubtitle" trans></p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2079c47b elementor-mobile-align-center animated-fast elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button" data-element_type="widget" data-settings='{"_animation":"fadeInUp","_animation_delay":10}' data-widget_type="button.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                        <a href="#detail" style="border-radius: 5rem" class="get-started-button elementor-button-link elementor-button elementor-size-md elementor-animation-grow" role="button" *ngIf="content?.actions?.cta1" trans>
                                            <span class="elementor-button-content-wrapper" style="display: flex; align-items: center; column-gap: 0.5rem">
                                                <i class="fa fa-hand-pointer"></i>
                                                <span class="elementor-button-text">{{ content.actions.cta1 }}</span>
                                            </span>
                                        </a>
                                        <a href="#detail" class="learn-more-button elementor-button-link elementor-button elementor-size-md elementor-animation-grow" style="background-color: #fff; color: #3246d3" role="button" *ngIf="content?.actions?.cta2" trans>
                                            <span class="elementor-button-content-wrapper">
                                                <span class="elementor-button-text">{{ content.actions.cta2 }}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-28d8de9c elementor-widget elementor-widget-spacer" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-column .elementor-spacer-inner {
                                            height: var(--spacer-size);
                                        }
                                        .e-con {
                                            --container-widget-width: 100%;
                                        }
                                        .e-con-inner > .elementor-widget-spacer,
                                        .e-con > .elementor-widget-spacer {
                                            width: var(--container-widget-width, var(--spacer-size));
                                            --align-self: var(--container-widget-align-self, initial);
                                            --flex-shrink: 0;
                                        }
                                        .e-con-inner > .elementor-widget-spacer > .elementor-widget-container,
                                        .e-con-inner > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer,
                                        .e-con > .elementor-widget-spacer > .elementor-widget-container,
                                        .e-con > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer {
                                            height: 100%;
                                        }
                                        .e-con-inner > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer > .elementor-spacer-inner,
                                        .e-con > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer > .elementor-spacer-inner {
                                            height: var(--container-widget-height, var(--spacer-size));
                                        }
                                    </style>
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-section-id="section-1" class="elementor-section elementor-top-section elementor-element elementor-element-7226c996 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-baed891" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-76ae518a animated-fast elementor-widget elementor-widget-image" data-element_type="widget" data-settings='{"_animation":"fadeInUp","_animation_delay":100}' data-widget_type="image.default">
                                <div class="elementor-widget-container hero">
                                    <img decoding="async" width="1043" height="614" [src]="content.headerImage" alt="" sizes="(max-width: 1043px) 100vw, 1043px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ng-container *ngIf="content.testimonialFeatures.length">
                <section data-section-id="section-2" class="elementor-section elementor-top-section elementor-element elementor-element-73e4df37 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" id="detail" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f88d4" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-576859bf elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default testimonialHeader-subtitle">{{ content.testimonialHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-734fa430 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default testimonialHeader-title">{{ content.testimonialHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-5edbcfdb elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="testimonialHeader-description">{{ content.testimonialHeader.description }}</p>
                                    </div>
                                </div>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-22b606dc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1f7e80ea inline-feature" data-element_type="column" data-settings='{"background_background":"classic"}' *ngFor="let testimonialFeature of content.testimonialFeatures">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-40859e5 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-widget_type="image-box.default">
                                                    <div class="elementor-widget-container">
                                                        <style>
                                                            /*! elementor - v3.11.5 - 14-03-2023 */
                                                            .elementor-widget-image-box .elementor-image-box-content {
                                                                width: 100%;
                                                            }
                                                            @media (min-width: 768px) {
                                                                .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper,
                                                                .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
                                                                    display: flex;
                                                                }
                                                                .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
                                                                    text-align: right;
                                                                    flex-direction: row-reverse;
                                                                }
                                                                .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
                                                                    text-align: left;
                                                                    flex-direction: row;
                                                                }
                                                                .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
                                                                    margin: auto;
                                                                }
                                                                .elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper {
                                                                    align-items: flex-start;
                                                                }
                                                                .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
                                                                    align-items: center;
                                                                }
                                                                .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper {
                                                                    align-items: flex-end;
                                                                }
                                                            }
                                                            @media (max-width: 767px) {
                                                                .elementor-widget-image-box .elementor-image-box-img {
                                                                    margin-left: auto !important;
                                                                    margin-right: auto !important;
                                                                    margin-bottom: 15px;
                                                                }
                                                            }
                                                            .elementor-widget-image-box .elementor-image-box-img {
                                                                display: inline-block;
                                                            }
                                                            .elementor-widget-image-box .elementor-image-box-title a {
                                                                color: inherit;
                                                            }
                                                            .elementor-widget-image-box .elementor-image-box-wrapper {
                                                                text-align: center;
                                                            }
                                                            .elementor-widget-image-box .elementor-image-box-description {
                                                                margin: 0;
                                                            }
                                                        </style>
                                                        <div class="elementor-image-box-wrapper">
                                                            <figure class="elementor-image-box-img">
                                                                <img style="border-radius: 50%" decoding="async" width="128" height="128" [src]="testimonialFeature.avatar" class="attachment-full size-full wp-image-69" alt="" />
                                                            </figure>
                                                            <div class="elementor-image-box-content">
                                                                <p class="testimonial-name elementor-image-box-title">{{ testimonialFeature.name }}</p>
                                                                <p class="testimonial-job elementor-image-box-description">{{ testimonialFeature.job }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-14ef55c2 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-element_type="widget" data-widget_type="divider.default">
                                                    <div class="elementor-widget-container">
                                                        <style>
                                                            /*! elementor - v3.11.5 - 14-03-2023 */
                                                            .elementor-widget-divider {
                                                                --divider-border-style: none;
                                                                --divider-border-width: 1px;
                                                                --divider-color: #2c2c2c;
                                                                --divider-icon-size: 20px;
                                                                --divider-element-spacing: 10px;
                                                                --divider-pattern-height: 24px;
                                                                --divider-pattern-size: 20px;
                                                                --divider-pattern-url: none;
                                                                --divider-pattern-repeat: repeat-x;
                                                            }
                                                            .elementor-widget-divider .elementor-divider {
                                                                display: flex;
                                                            }
                                                            .elementor-widget-divider .elementor-divider__text {
                                                                font-size: 15px;
                                                                line-height: 1;
                                                                max-width: 95%;
                                                            }
                                                            .elementor-widget-divider .elementor-divider__element {
                                                                margin: 0 var(--divider-element-spacing);
                                                                flex-shrink: 0;
                                                            }
                                                            .elementor-widget-divider .elementor-icon {
                                                                font-size: var(--divider-icon-size);
                                                            }
                                                            .elementor-widget-divider .elementor-divider-separator {
                                                                display: flex;
                                                                margin: 0;
                                                                direction: ltr;
                                                            }
                                                            .elementor-widget-divider--view-line_icon .elementor-divider-separator,
                                                            .elementor-widget-divider--view-line_text .elementor-divider-separator {
                                                                align-items: center;
                                                            }
                                                            .elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
                                                            .elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
                                                            .elementor-widget-divider--view-line_text .elementor-divider-separator:after,
                                                            .elementor-widget-divider--view-line_text .elementor-divider-separator:before {
                                                                display: block;
                                                                content: '';
                                                                border-bottom: 0;
                                                                flex-grow: 1;
                                                                border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color);
                                                            }
                                                            .elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator > .elementor-divider__svg:first-of-type {
                                                                flex-grow: 0;
                                                                flex-shrink: 100;
                                                            }
                                                            .elementor-widget-divider--element-align-left .elementor-divider-separator:before {
                                                                content: none;
                                                            }
                                                            .elementor-widget-divider--element-align-left .elementor-divider__element {
                                                                margin-left: 0;
                                                            }
                                                            .elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator > .elementor-divider__svg:last-of-type {
                                                                flex-grow: 0;
                                                                flex-shrink: 100;
                                                            }
                                                            .elementor-widget-divider--element-align-right .elementor-divider-separator:after {
                                                                content: none;
                                                            }
                                                            .elementor-widget-divider--element-align-right .elementor-divider__element {
                                                                margin-right: 0;
                                                            }
                                                            .elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
                                                                border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color);
                                                            }
                                                            .elementor-widget-divider--separator-type-pattern {
                                                                --divider-border-style: none;
                                                            }
                                                            .elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
                                                            .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
                                                            .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
                                                            .elementor-widget-divider--separator-type-pattern:not([class*='elementor-widget-divider--view']) .elementor-divider-separator {
                                                                width: 100%;
                                                                min-height: var(--divider-pattern-height);
                                                                -webkit-mask-size: var(--divider-pattern-size) 100%;
                                                                mask-size: var(--divider-pattern-size) 100%;
                                                                -webkit-mask-repeat: var(--divider-pattern-repeat);
                                                                mask-repeat: var(--divider-pattern-repeat);
                                                                background-color: var(--divider-color);
                                                                -webkit-mask-image: var(--divider-pattern-url);
                                                                mask-image: var(--divider-pattern-url);
                                                            }
                                                            .elementor-widget-divider--no-spacing {
                                                                --divider-pattern-size: auto;
                                                            }
                                                            .elementor-widget-divider--bg-round {
                                                                --divider-pattern-repeat: round;
                                                            }
                                                            .rtl .elementor-widget-divider .elementor-divider__text {
                                                                direction: rtl;
                                                            }
                                                            .e-con-inner > .elementor-widget-divider,
                                                            .e-con > .elementor-widget-divider {
                                                                width: var(--container-widget-width, 100%);
                                                                --flex-grow: var(--container-widget-flex-grow);
                                                            }
                                                        </style>
                                                        <div class="elementor-divider">
                                                            <span class="elementor-divider-separator"> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-2f76c4a3 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p class="testimonial-description">{{ testimonialFeature.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>
            <section data-section-id="section-3" class="elementor-section elementor-top-section elementor-element elementor-element-2eeda00f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-310459cd" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-2446e597 elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img decoding="async" width="471" height="572" [src]="content.problemHeader.image" class="attachment-large size-large wp-image-72 problemHeader-image" alt="" srcset="{{ content.problemHeader.image }} 471w, {{ content.problemHeader.image }} 247w" sizes="(max-width: 471px) 100vw, 471px" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4db6c304" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-6a74ad57 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <p class="elementor-heading-title elementor-size-default problemHeader-subtitle">{{ content.problemHeader.subtitle }}</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-df2f3b5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default problemHeader-title">{{ content.problemHeader.title }}</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2658a354 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p class="problemHeader-description">{{ content.problemHeader.description }}</p>
                                </div>
                            </div>
                            <ng-container *ngIf="content.problemFeatures.length">
                                <div class="elementor-element elementor-element-3d6adb15 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-element_type="widget" data-widget_type="icon-list.default">
                                    <div class="elementor-widget-container">
                                        <ul class="elementor-icon-list-items">
                                            <li class="inline-feature elementor-icon-list-item" style="border: none; padding: 1rem; margin: 0" *ngFor="let problemFeature of content.problemFeatures">
                                                <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-times"></i> </span>
                                                <span class="elementor-icon-list-text" style="font-size: small">{{ problemFeature.content }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
            <section data-section-id="section-4" class="elementor-section elementor-top-section elementor-element elementor-element-2eeda00f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4db6c304" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-6a74ad57 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <p class="elementor-heading-title elementor-size-default problemHeader-subtitle">SOLUSI</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-df2f3b5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default problemHeader-title">Jika Semua atau salahsatunya sedang Anda Alami, Ini dia Solusinya</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2658a354 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p class="problemHeader-description">Satu Platform menyelesaikan semua Masalah Landing Page Anda</p>
                                </div>
                            </div>
                            <ng-container>
                                <div class="elementor-element elementor-element-3d6adb15 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-element_type="widget" data-widget_type="icon-list.default">
                                    <div class="elementor-widget-container">
                                        <ul class="elementor-icon-list-items">
                                            <li class="inline-feature elementor-icon-list-item" *ngFor="let solution of solutions" style="border: none; padding: 1rem; margin: 0">
                                                <span class="elementor-icon-list-icon"> <i aria-hidden="true" style="color: var(--e-global-color-primary)" class="fas fa-check"></i> </span>
                                                <span class="elementor-icon-list-text" style="font-size: small">{{ solution.value }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-310459cd" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-2446e597 elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img style="border-radius: 2.25rem" decoding="async" width="471" height="572" src="https://asset-prospector.vercel.app/landing-page/banner-3.jpg" class="attachment-large size-large wp-image-72 problemHeader-image" alt="" srcset="https://asset-prospector.vercel.app/landing-page/banner-3.jpg 471w, https://asset-prospector.vercel.app/landing-page/banner-3.jpg 247w" sizes="(max-width: 471px) 100vw, 471px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style="padding-bottom: 0" data-section-id="section-2" class="elementor-section elementor-top-section elementor-element elementor-element-73e4df37 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" id="detail" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f88d4" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-576859bf elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <p class="elementor-heading-title elementor-size-default testimonialHeader-subtitle">Memperkenalkan</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-734fa430 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default testimonialHeader-title">Kini Telah Hadir PROSPECTOR.ID</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5edbcfdb elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p class="testimonialHeader-description">Platform Si Serba automatis yang akan menghandle semua pengerjaan Landing Page dan Form Builder Anda.</p>
                                </div>
                            </div>
                            <style>
                                .element-prospector .elementor-heading-title {
                                    color: #f0745a;
                                    font-family: 'Source Sans Pro', Sans-serif;
                                    font-size: 32px;
                                    font-weight: 700;
                                    line-height: 1.3em;
                                }
                            </style>
                            <div class="element-prospector elementor-element elementor-element-656d3143 elementor-widget elementor-widget-heading" data-id="656d3143" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 style="text-align: center" class="elementor-heading-title elementor-size-default">5 Fitur Utama yang akan memudahkan Anda</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ng-container *ngFor="let secondaryFeature of content.secondaryFeatures; first as first; last as last; even as even">
                <section data-section-id="section-5" style="width: 100%" class="elementor-section elementor-top-section elementor-element elementor-element-75bdd76f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="big-feature elementor-container elementor-column-gap-wide" [class.first-secondary-feature]="first" [class.reverse]="even">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3abdd4ff" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-f28d52c elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                    <div>
                                        <img decoding="async" style="width: 100%" [src]="secondaryFeature.image + '?v2'" class="attachment-large size-large wp-image-73" alt="" srcset="{{ secondaryFeature.image }} 786w, {{ secondaryFeature.image }} 300w, {{ secondaryFeature.image }} 768w" sizes="(max-width: 786px) 100vw, 786px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2785f536" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1c135c5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default secondary-subtitle">{{ secondaryFeature.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-3eaf1a05 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default secondary-title">{{ secondaryFeature.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-66cb96f2 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="secondary-description" [innerHTML]="secondaryFeature.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>
            <!-- <ng-container *ngIf="content.introduceHeader || content.introduceFeatures">
                <section data-section-id="section-6" class="elementor-section elementor-top-section elementor-element elementor-element-56b41736 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-796f8e75" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-70e0d452 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"animation":"fadeInUp"}'>
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-27516a04" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-cf7f6f0 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <p class="elementor-heading-title elementor-size-default introduceHeader-subtitle">{{ content.introduceHeader.subtitle }}</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-28da2c36 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h3 class="elementor-heading-title elementor-size-default introduceHeader-title">{{ content.introduceHeader.title }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-6137f9aa" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-4f1696e elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p class="introduceHeader-description">{{ content.introduceHeader.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-73ba529b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div *ngFor="let introduceFeature of content.introduceFeatures" class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-50647dcf" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated big-feature">
                                                <div class="elementor-element elementor-element-4707d0d5 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image-box.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-image-box-wrapper">
                                                            <figure class="elementor-image-box-img"><img decoding="async" width="50" height="51" [src]="introduceFeature.image" class="attachment-full size-full wp-image-75" alt="" /></figure>
                                                            <div class="elementor-image-box-content">
                                                                <p class="elementor-image-box-title introduce-title">{{ introduceFeature.title }}</p>
                                                                <p class="elementor-image-box-description introduce-description">{{ introduceFeature.description }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div class="elementor-element elementor-element-2efcf7b5 elementor-widget elementor-widget-image" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img decoding="async" width="960" height="273" [src]="content.introduceHeader.image" class="attachment-large size-large wp-image-77 introduceHeader-image" alt="" srcset="{{ content.introduceHeader.image }} 1024w, {{ content.introduceHeader.image }} 300w, {{ content.introduceHeader.image }} 768w, {{ content.introduceHeader.image }} 1536w, {{ content.introduceHeader.image }} 2048w" sizes="(max-width: 960px) 100vw, 960px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container> -->
            <ng-container *ngIf="content.forHeader">
                <section data-section-id="section-7" class="elementor-section elementor-top-section elementor-element elementor-element-6ed75af9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3b21b6b8" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1d52da8e elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default forHeader-subtitle">{{ content.forHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-45256c3e elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default forHeader-title">{{ content.forHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-6b4b29c7 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="forHeader-description">{{ content.forHeader.description }}</p>
                                    </div>
                                </div>
                                <ng-container *ngIf="content.forFeatures.length">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-43a774d7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-wide">
                                            <div *ngFor="let forFeature of content.forFeatures" class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-44223541" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-15cf072e elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image-box.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-image-box-wrapper">
                                                                <figure class="elementor-image-box-img"><img decoding="async" width="266" height="267" [src]="forFeature.image" class="attachment-full size-full wp-image-78" alt="" srcset="{{ forFeature.image }} 266w, {{ forFeature.image }} 150w" sizes="(max-width: 266px) 100vw, 266px" /></figure>
                                                                <div class="elementor-image-box-content">
                                                                    <p class="elementor-image-box-title">{{ forFeature.title }}</p>
                                                                    <p class="elementor-image-box-description">{{ forFeature.description }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>
            <ng-container *ngIf="content.reasonHeader">
                <section data-section-id="section-8" class="elementor-section elementor-top-section elementor-element elementor-element-36fc5aa1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-560fdbf7" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7697f113 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default reasonHeader-subtitle">{{ content.reasonHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-6626d77b elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default reasonHeader-title">{{ content.reasonHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-7a346b4f elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="reasonHeader-description">{{ content.reasonHeader.description }}</p>
                                    </div>
                                </div>
                                <ng-container *ngIf="content.reasonFeatures.length">
                                    <section style="margin-top: 1rem" class="elementor-section elementor-inner-section elementor-element elementor-element-5a08078 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                        <div class="reason-group elementor-container">
                                            <div *ngFor="let reasonFeature of content.reasonFeatures" class="elementor-element elementor-element-fa29fde elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image-box.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image-box-wrapper">
                                                        <figure class="elementor-image-box-img"><img decoding="async" width="60" height="61" [src]="reasonFeature.image" class="attachment-full size-full wp-image-76 reason-image" alt="" /></figure>
                                                        <div class="elementor-image-box-content">
                                                            <p class="elementor-image-box-title reason-title">{{ reasonFeature.title }}</p>
                                                            <p class="elementor-image-box-description reason-description">{{ reasonFeature.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>
            <!-- <ng-container *ngIf="content.testimonialTwoHeader">
                <section data-section-id="section-9" class="elementor-section elementor-top-section elementor-element elementor-element-7383c751 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2aaf4d9c" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7932b9de elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default testimonialTwoHeader-subtitle">{{ content.testimonialTwoHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-21c639eb elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default testimonialTwoHeader-title">{{ content.testimonialTwoHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-66c82c33 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="testimonialTwoHeader-description">{{ content.testimonialTwoHeader.description }}</p>
                                    </div>
                                </div>
                                <ng-container *ngIf="content.testimonialTwoFeatures.length">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-492cc90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                        <div class="testimonial-two-group elementor-container elementor-column-gap-wide">
                                            <div *ngFor="let testimonialTwoFeature of content.testimonialTwoFeatures" class="elementor-column elementor-inner-column elementor-element elementor-element-15c81605" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-70970687 elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                                        <div class="elementor-widget-container">
                                                            <img decoding="async" width="364" height="357" [src]="testimonialTwoFeature.image" class="attachment-large size-large wp-image-82 testimonial-two-image" alt="" srcset="{{ testimonialTwoFeature.image }} 364w, {{ testimonialTwoFeature.image }} 300w" sizes="(max-width: 364px) 100vw, 364px" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container> -->
            <!-- <ng-container *ngIf="content.pricingHeader">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-28179bf8 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-background-overlay"></div>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5f48512f" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-51eddcb5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default pricingHeader-subtitle">{{ content.pricingHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-3ab3c654 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default pricingHeader-title">{{ content.pricingHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-4fcc57c elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="pricingHeader-description" style="white-space: pre-line" [innerHTML]="content.pricingHeader.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngFor="let pricingFeature of content.pricingFeatures; let i = index">
                            <div *ngIf="!i" class="pricing-group elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7332290a" data-element_type="column" data-settings='{"background_background":"classic","animation":"fadeInUp"}'>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-18d745d3 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default pricing-title">{{ pricingFeature.title }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2503c111 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default pricing-ori-price">{{ pricingFeature.oriPrice }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4db5825e elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default pricing-price">{{ pricingFeature.price }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-347142c0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-element_type="widget" data-widget_type="divider.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-divider">
                                                <span class="elementor-divider-separator"> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-79c3a45a elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items">
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Feedback System PlugApp</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">PlugApp Social Integration</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Planning Solution PlugApp</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Push Notification PlugApp</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-1b9d5141 elementor-mobile-align-justify elementor-align-justify elementor-widget elementor-widget-button" data-element_type="widget" data-widget_type="button.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-button-wrapper">
                                                <a [href]="pricingFeature.buttonUrl || '#'" class="elementor-button-link elementor-button elementor-size-md elementor-animation-grow" role="button">
                                                    <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-text">{{ pricingFeature.buttonText || 'Beli Sekarang' }}</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-1d313391 elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img decoding="async" width="261" height="16" src="{{ settings.get('base_url') }}/assets/homepage/uploads/2023/04/bank-white-1.png" class="attachment-large size-large wp-image-84" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="i" class="pricing-group elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-57ce1aa5" data-element_type="column" data-settings='{"background_background":"classic","animation":"fadeInUp"}'>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-3ec4a7c5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">{{ pricingFeature.title }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-1bc67c49 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">{{ pricingFeature.oriPrice }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-48d82a82 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <p class="elementor-heading-title elementor-size-default">{{ pricingFeature.price }}</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2af2fc7e elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-element_type="widget" data-widget_type="divider.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-divider">
                                                <span class="elementor-divider-separator"> </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-6590633f elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items">
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Feedback System PlugApp</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">PlugApp Social Integration</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Planning Solution PlugApp</span>
                                                </li>
                                                <li class="elementor-icon-list-item">
                                                    <span class="elementor-icon-list-icon"> <i aria-hidden="true" class="fas fa-check"></i> </span>
                                                    <span class="elementor-icon-list-text">Push Notification PlugApp</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-24928395 elementor-mobile-align-justify elementor-align-justify elementor-widget elementor-widget-button" data-element_type="widget" data-widget_type="button.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-button-wrapper">
                                                <a [href]="pricingFeature.buttonUrl" class="elementor-button elementor-size-md" role="button">
                                                    <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-text">{{ pricingFeature.buttonText }}</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-7962ca4f elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img decoding="async" width="219" height="13" src="{{ settings.get('base_url') }}/assets/homepage/uploads/2023/04/bank-white-2.png" class="attachment-large size-large wp-image-86" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </section>
            </ng-container> -->
            <!-- <ng-container *ngIf="content.guaranteeHeader">
                <section data-section-id="section-11" class="elementor-section elementor-top-section elementor-element elementor-element-27ab1d17 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-355fb9c0" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-368eaeec elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img decoding="async" width="362" height="271" [src]="content.guaranteeHeader.image" class="attachment-large size-large wp-image-87 guaranteeHeader-image" srcset="{{ content.guaranteeHeader.image }} 362w, {{ content.guaranteeHeader.image }} 300w" sizes="(max-width: 362px) 100vw, 362px" />
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-754c38af elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default guaranteeHeader-subtitle">{{ content.guaranteeHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-6f95054e elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default guaranteeHeader-title">{{ content.guaranteeHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-747c5cc5 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="guaranteeHeader-description">{{ content.guaranteeHeader.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container> -->
            <ng-container *ngIf="content.callToActionHeader">
                <section data-section-id="section-12" class="elementor-section elementor-top-section elementor-element elementor-element-2bbe45a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-73d44444" data-element_type="column" data-settings='{"animation":"fadeInUp","background_background":"classic"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-3dbeff8f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2aa05b41" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-1948f7c3 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h3 class="elementor-heading-title elementor-size-default callToActionHeader-title">{{ content.callToActionHeader.title }}</h3>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-a28dce2 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p class="callToActionHeader-description">{{ content.callToActionHeader.description }}</p>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-1c06b0ea elementor-mobile-align-justify elementor-align-left elementor-widget elementor-widget-button" data-element_type="widget" data-widget_type="button.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-button-wrapper">
                                                            <a [href]="content.callToActionHeader.buttonUrl" class="elementor-button-link elementor-button elementor-size-md elementor-animation-grow callToActionHeader-buttonText callToActionHeader-buttonUrl" role="button">
                                                                <span class="elementor-button-content-wrapper">
                                                                    <span class="elementor-button-text">{{ content.callToActionHeader.buttonText }}</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-330d6ea2" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-32921bd elementor-widget elementor-widget-image" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img decoding="async" width="542" height="481" [src]="content.callToActionHeader.image" class="attachment-large size-large wp-image-88" alt="" sizes="(max-width: 542px) 100vw, 542px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-container>
            <ng-container *ngIf="content.faqHeader">
                <section data-section-id="section-13" class="elementor-section elementor-top-section elementor-element elementor-element-100952b0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                    <div class="elementor-container elementor-column-gap-wide">
                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a30813a" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1a57d5a elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <p class="elementor-heading-title elementor-size-default faqHeader-subtitle">{{ content.faqHeader.subtitle }}</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-1cb2d42e elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h3 class="elementor-heading-title elementor-size-default faqHeader-title">{{ content.faqHeader.title }}</h3>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-3686031a elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p style="white-space: pre-line" class="faqHeader-description" [innerHTML]="content.faqHeader.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="content.faqFeatures.length">
                            <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-52fa48b0" data-element_type="column" data-settings='{"animation":"fadeInUp"}'>
                                <div class="faq-group elementor-widget-wrap elementor-element-populated">
                                    <div *ngFor="let faqFeature of content.faqFeatures" class="elementor-element elementor-element-685354de elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-"> <i aria-hidden="true" class="fas fa-question"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <p class="elementor-icon-box-title faq-title">
                                                        <span> {{ faqFeature.title }} </span>
                                                    </p>
                                                    <p class="elementor-icon-box-description faq-description">{{ faqFeature.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </section>
            </ng-container>
            <section data-section-id="14" class="elementor-section elementor-top-section elementor-element elementor-element-63a990d3 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-51245e4a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <ng-container *ngIf="content.footerAuthor">
                                <div style="display: flex; justify-content: center; width: 100%; column-gap: 5rem">
                                    <div class="elementor-element elementor-element-4f004424 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-widget_type="image-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-image-box-wrapper">
                                                <figure class="elementor-image-box-img"><img style="border-radius: 50%" decoding="async" width="128" height="128" [src]="content.footerAuthor.avatar" class="attachment-full size-full wp-image-90 footerAuthor-image" alt="" /></figure>
                                                <div class="elementor-image-box-content">
                                                    <p class="elementor-image-box-title footerAuthor-title">{{ content.footerAuthor.name }}</p>
                                                    <p class="elementor-image-box-description footerAuthor-subtitle">{{ content.footerAuthor.job }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4f004424 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-element_type="widget" data-widget_type="image-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-image-box-wrapper">
                                                <figure class="elementor-image-box-img"><img style="border-radius: 50%" decoding="async" width="128" height="128" src="https://asset-prospector.vercel.app/landing-page/people/2.jpg" class="attachment-full size-full wp-image-90 footerAuthor-image" alt="" /></figure>
                                                <div class="elementor-image-box-content">
                                                    <p class="elementor-image-box-title footerAuthor-title">Yusep Maulana</p>
                                                    <p class="elementor-image-box-description footerAuthor-subtitle">IT Development</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-72e10836 elementor-widget elementor-widget-spacer" data-element_type="widget" data-widget_type="spacer.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-43b4a71b animated-fast elementor-widget elementor-widget-text-editor" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="footerAuthor-description">{{ content.footerAuthor.description }}</p>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="elementor-element elementor-element-4b38b378 animated-fast elementor-shape-rounded elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="social-icons.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container,
                                        .elementor-widget-social-icons.elementor-grid-mobile-0 .elementor-widget-container,
                                        .elementor-widget-social-icons.elementor-grid-tablet-0 .elementor-widget-container {
                                            line-height: 1;
                                            font-size: 0;
                                        }
                                        .elementor-widget-social-icons:not(.elementor-grid-0):not(.elementor-grid-tablet-0):not(.elementor-grid-mobile-0) .elementor-grid {
                                            display: inline-grid;
                                        }
                                        .elementor-widget-social-icons .elementor-grid {
                                            grid-column-gap: var(--grid-column-gap, 5px);
                                            grid-row-gap: var(--grid-row-gap, 5px);
                                            grid-template-columns: var(--grid-template-columns);
                                            justify-content: var(--justify-content, center);
                                            justify-items: var(--justify-content, center);
                                        }
                                        .elementor-icon.elementor-social-icon {
                                            font-size: var(--icon-size, 25px);
                                            line-height: var(--icon-size, 25px);
                                            width: calc(var(--icon-size, 25px) + (2 * var(--icon-padding, 0.5em)));
                                            height: calc(var(--icon-size, 25px) + (2 * var(--icon-padding, 0.5em)));
                                        }
                                        .elementor-social-icon {
                                            --e-social-icon-icon-color: #fff;
                                            display: inline-flex;
                                            background-color: #818a91;
                                            align-items: center;
                                            justify-content: center;
                                            text-align: center;
                                            cursor: pointer;
                                        }
                                        .elementor-social-icon i {
                                            color: var(--e-social-icon-icon-color);
                                        }
                                        .elementor-social-icon svg {
                                            fill: var(--e-social-icon-icon-color);
                                        }
                                        .elementor-social-icon:last-child {
                                            margin: 0;
                                        }
                                        .elementor-social-icon:hover {
                                            opacity: 0.9;
                                            color: #fff;
                                        }
                                        .elementor-social-icon-android {
                                            background-color: #a4c639;
                                        }
                                        .elementor-social-icon-apple {
                                            background-color: #999;
                                        }
                                        .elementor-social-icon-behance {
                                            background-color: #1769ff;
                                        }
                                        .elementor-social-icon-bitbucket {
                                            background-color: #205081;
                                        }
                                        .elementor-social-icon-codepen {
                                            background-color: #000;
                                        }
                                        .elementor-social-icon-delicious {
                                            background-color: #39f;
                                        }
                                        .elementor-social-icon-deviantart {
                                            background-color: #05cc47;
                                        }
                                        .elementor-social-icon-digg {
                                            background-color: #005be2;
                                        }
                                        .elementor-social-icon-dribbble {
                                            background-color: #ea4c89;
                                        }
                                        .elementor-social-icon-elementor {
                                            background-color: #d30c5c;
                                        }
                                        .elementor-social-icon-envelope {
                                            background-color: #ea4335;
                                        }
                                        .elementor-social-icon-facebook,
                                        .elementor-social-icon-facebook-f {
                                            background-color: #3b5998;
                                        }
                                        .elementor-social-icon-flickr {
                                            background-color: #0063dc;
                                        }
                                        .elementor-social-icon-foursquare {
                                            background-color: #2d5be3;
                                        }
                                        .elementor-social-icon-free-code-camp,
                                        .elementor-social-icon-freecodecamp {
                                            background-color: #006400;
                                        }
                                        .elementor-social-icon-github {
                                            background-color: #333;
                                        }
                                        .elementor-social-icon-gitlab {
                                            background-color: #e24329;
                                        }
                                        .elementor-social-icon-globe {
                                            background-color: #818a91;
                                        }
                                        .elementor-social-icon-google-plus,
                                        .elementor-social-icon-google-plus-g {
                                            background-color: #dd4b39;
                                        }
                                        .elementor-social-icon-houzz {
                                            background-color: #7ac142;
                                        }
                                        .elementor-social-icon-instagram {
                                            background-color: #262626;
                                        }
                                        .elementor-social-icon-jsfiddle {
                                            background-color: #487aa2;
                                        }
                                        .elementor-social-icon-link {
                                            background-color: #818a91;
                                        }
                                        .elementor-social-icon-linkedin,
                                        .elementor-social-icon-linkedin-in {
                                            background-color: #0077b5;
                                        }
                                        .elementor-social-icon-medium {
                                            background-color: #00ab6b;
                                        }
                                        .elementor-social-icon-meetup {
                                            background-color: #ec1c40;
                                        }
                                        .elementor-social-icon-mixcloud {
                                            background-color: #273a4b;
                                        }
                                        .elementor-social-icon-odnoklassniki {
                                            background-color: #f4731c;
                                        }
                                        .elementor-social-icon-pinterest {
                                            background-color: #bd081c;
                                        }
                                        .elementor-social-icon-product-hunt {
                                            background-color: #da552f;
                                        }
                                        .elementor-social-icon-reddit {
                                            background-color: #ff4500;
                                        }
                                        .elementor-social-icon-rss {
                                            background-color: #f26522;
                                        }
                                        .elementor-social-icon-shopping-cart {
                                            background-color: #4caf50;
                                        }
                                        .elementor-social-icon-skype {
                                            background-color: #00aff0;
                                        }
                                        .elementor-social-icon-slideshare {
                                            background-color: #0077b5;
                                        }
                                        .elementor-social-icon-snapchat {
                                            background-color: #fffc00;
                                        }
                                        .elementor-social-icon-soundcloud {
                                            background-color: #f80;
                                        }
                                        .elementor-social-icon-spotify {
                                            background-color: #2ebd59;
                                        }
                                        .elementor-social-icon-stack-overflow {
                                            background-color: #fe7a15;
                                        }
                                        .elementor-social-icon-steam {
                                            background-color: #00adee;
                                        }
                                        .elementor-social-icon-stumbleupon {
                                            background-color: #eb4924;
                                        }
                                        .elementor-social-icon-telegram {
                                            background-color: #2ca5e0;
                                        }
                                        .elementor-social-icon-thumb-tack {
                                            background-color: #1aa1d8;
                                        }
                                        .elementor-social-icon-tripadvisor {
                                            background-color: #589442;
                                        }
                                        .elementor-social-icon-tumblr {
                                            background-color: #35465c;
                                        }
                                        .elementor-social-icon-twitch {
                                            background-color: #6441a5;
                                        }
                                        .elementor-social-icon-twitter {
                                            background-color: #1da1f2;
                                        }
                                        .elementor-social-icon-viber {
                                            background-color: #665cac;
                                        }
                                        .elementor-social-icon-vimeo {
                                            background-color: #1ab7ea;
                                        }
                                        .elementor-social-icon-vk {
                                            background-color: #45668e;
                                        }
                                        .elementor-social-icon-weibo {
                                            background-color: #dd2430;
                                        }
                                        .elementor-social-icon-weixin {
                                            background-color: #31a918;
                                        }
                                        .elementor-social-icon-whatsapp {
                                            background-color: #25d366;
                                        }
                                        .elementor-social-icon-wordpress {
                                            background-color: #21759b;
                                        }
                                        .elementor-social-icon-xing {
                                            background-color: #026466;
                                        }
                                        .elementor-social-icon-yelp {
                                            background-color: #af0606;
                                        }
                                        .elementor-social-icon-youtube {
                                            background-color: #cd201f;
                                        }
                                        .elementor-social-icon-500px {
                                            background-color: #0099e5;
                                        }
                                        .elementor-shape-rounded .elementor-icon.elementor-social-icon {
                                            border-radius: 10%;
                                        }
                                        .elementor-shape-circle .elementor-icon.elementor-social-icon {
                                            border-radius: 50%;
                                        }
                                    </style>
                                    <ng-container *ngIf="content.footerSocialMediaFeatures.length">
                                        <div class="footer-social-media-group elementor-social-icons-wrapper elementor-grid" style="display: flex; column-gap: 0.5rem">
                                            <span *ngFor="let social of content.footerSocialMediaFeatures" class="elementor-grid-item">
                                                <a class="footer-social-media-url elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-grow elementor-repeater-item-f5df74a" [href]="social.url" target="_blank">
                                                    <span class="elementor-screen-only">{{ social.url }}</span>
                                                    <i class="fab fa-{{ social.icon }}"></i>
                                                </a>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-51c32e8c animated-fast elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="divider.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator"> </span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="content.footerCopyright">
                                <div class="elementor-element elementor-element-64833fb4 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="footerCopyright-description" [innerHTML]="content.footerCopyright.description"></p>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </body>
</html>
